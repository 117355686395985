import { ReactNode, useEffect } from "react";
import Disclaimer from "../Disclaimer";
import Footer from "../Footer";
import Header from "../Header";
import useTabFocusStyle from "../../hooks/useTabFocusStyle";
import Analytics from "../Analytics";

interface ILayoutProps {
  children: ReactNode;
  data: any;
  uri: string;
}

export default function Layout({ children, data, uri }: ILayoutProps) {
  useTabFocusStyle();

  const disclaimerData = data?.sanitySettings?._rawDisclaimer;
  const gaId = data?.sanityAnalyticsSettings?.gaId;
  const metaPixelId = data?.sanityAnalyticsSettings?.metaPixelId;
  const linkedInPartnerId = data?.sanityAnalyticsSettings?.linkedInPartnerId;
  const favicon = data?.sanitySettings?.favicon;

  useEffect(() => {
    if (favicon) {
      let link: HTMLLinkElement | null =
        document.querySelector("link[rel='icon']");

      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }

      link.href = favicon?.asset?.url;
    }
  }, [favicon]);

  return (
    <>
      <Analytics
        gaId={gaId}
        metaPixelId={metaPixelId}
        linkedInPartnerId={linkedInPartnerId}
      />
      {!!disclaimerData && <Disclaimer data={disclaimerData} />}
      <Header data={data} uri={uri} />
      {children}
      <Footer data={data} />
    </>
  );
}
